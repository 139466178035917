import { useEffect, useState } from 'react';

// Define constants for item and link IDs
const ITEM_IDS = {
  MORE: 'more',
  ALTSAFE: 'altsafe'
};

const LINK_IDS = {
  RELATIONSHIP_MANAGER: 'relationship-manager',
  PREFERENCE: 'preference',
  MY_REFERRALS: 'my-referrals',
  TAX_OPTIMISER: 'tax-optimiser'
};

// Function to filter 'relationship-manager' link
const filterRelationshipManagerLink = (items, investorCache) => {
  if (!investorCache?.rmDetails) {
    return items.map((item) => {
      if (item.id === ITEM_IDS.MORE && item.moreLinks) {
        item.moreLinks = item.moreLinks.filter((link) => link.id !== LINK_IDS.RELATIONSHIP_MANAGER);
      }
      return item;
    });
  }
  return items;
};

// Function to filter 'altsafe' item and 'preference' link
const filterAltSafeAndPreferenceLink = (items, investorCache) => {
  if (!investorCache.showJiraafSafe) {
    items = items.filter((item) => item.id !== ITEM_IDS.ALTSAFE);
    return items.map((item) => {
      if (item.id === ITEM_IDS.MORE && item.moreLinks) {
        item.moreLinks = item.moreLinks.filter((link) => link.id !== LINK_IDS.PREFERENCE);
      }
      return item;
    });
  }
   // Check if there is a wallet balance and add highlight to altSafe
  if (investorCache.hasWalletBalance) {
      items = items.map((item) => {
          if (item.id === ITEM_IDS.ALTSAFE) {
              item.highlight = true;
          }
          return item;
      });
  }
  return items;
};

// Function to filter 'my-referrals' link
const filterMyReferralsLink = (items, investorCache) => {
  if (!investorCache.showReferral) {
    return items.map((item) => {
      if (item.id === ITEM_IDS.MORE && item.moreLinks) {
        item.moreLinks = item.moreLinks.filter((link) => link.id !== LINK_IDS.MY_REFERRALS);
      }
      return item;
    });
  }
  return items;
};

// Function to filter 'tax-optimizer' link
const filterTaxOptimiserLink = (items, isEligibleForTaxOptimiser) => {
  if (!isEligibleForTaxOptimiser) {
    return items.map((item) => {
      if (item.id === ITEM_IDS.MORE && item.moreLinks) {
        item.moreLinks = item.moreLinks.filter((link) => link.id !== LINK_IDS.TAX_OPTIMISER);
      }
      return item;
    });
  }
  return items;
};

const useBottomNavigation = ({ investorCache, defaultNavItems, isEligibleForTaxOptimiser }) => {
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    const filterNavItems = () => {
      let newNavItemList =  JSON.parse(JSON.stringify(defaultNavItems));
      newNavItemList = filterRelationshipManagerLink(newNavItemList, investorCache);
      newNavItemList = filterAltSafeAndPreferenceLink(newNavItemList, investorCache);
      newNavItemList = filterMyReferralsLink(newNavItemList, investorCache);
      newNavItemList = filterTaxOptimiserLink(newNavItemList, isEligibleForTaxOptimiser);

      setNavItems(newNavItemList);
    };

    filterNavItems();
  }, [investorCache]);

  return navItems;
};

export default useBottomNavigation;