import React, { useState } from 'react';
import texts from './en.json';
import { useRouter } from 'next/router';
import { CollapsibleBar } from './CollapsibleBar';
import 'animate.css/animate.min.css';
import RmElement from './RmElement';
import useBottomNavigation from '@hooks/useBottomNavigation';
import { getAuth } from '@services/identity.service';
import NavItems from './NavItems';
import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import { useInvestorContext } from '@context/InvestorContextProvider';

const BottomNavigation = ({ investorCache, visible = null, setIsBottomNavVisible }) => {
  const router = useRouter();
  const auth = getAuth();
  const { isEligibleForTaxOptimiser } = useInvestorContext();
  const isVisible = visible ?? (texts?.firstLevelPagesWithAuth.indexOf(router?.pathname) !== -1);
  const notShowBottomNavigation = !isVisible || !auth;
  const [viewMoreLinks, setViewMoreLinks] = useState([]);
  const navItems = useBottomNavigation({investorCache, defaultNavItems: texts?.navigationLinks, isEligibleForTaxOptimiser});
  const isAppView = router?.query.is_app == 'true';
  const listedUrlsExceptMoreLinks = texts.navigationLinks.reduce((acc, curr) => {
    acc.push(curr?.redirect)
    return acc;
  }, []);
  const [highlights, setHighlights] = useState('');  

  const redirectTo = (url) => {
    router.push(url);
    setViewMoreLinks([]);
  };

  const collapsibleElements = (id) => {
      const elements = {
        "relationship-manager": <RmElement rmDetails={investorCache?.rmDetails} gTag={gaEventNameEnum.CLICKED_CHAT_WITH_RM_MOBILE_WEB_NAVIGATION} />
      }

      return elements[id];
  };

  if(notShowBottomNavigation || isAppView) return;

  return (
      <div className="xl:hidden absolute">
          <NavItems
              {...{
                  navItems,
                  viewMoreLinks,
                  setViewMoreLinks,
                  redirectTo,
                  router,
                  listedUrlsExceptMoreLinks,
                  setIsBottomNavVisible,
                  highlights,
                  setHighlights
              }}
          />
          <CollapsibleBar
              list={viewMoreLinks}
              visible={viewMoreLinks.length > 0}
              redirectTo={redirectTo}
              setVisible={(val) => {
                if(!val) setHighlights('');
                setViewMoreLinks(val ? viewMoreLinks : [])
              }}
              collapsibleElements={collapsibleElements}
              router={router}
          />
      </div>
  );
};

export default BottomNavigation;