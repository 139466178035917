import { gaEventNameEnum } from '@helpers/enums/gaEventNameEnum';
import { triggerCustomEvent } from '@helpers/miscelleanous';
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/outline';
import useOutsideClick from '@hooks/useOutsideClick';
import { Image, Link, Text } from '@ui/atoms';
import React, { useEffect, useRef, useState } from 'react'
export const CollapsibleBar = ({
    visible,
    list,
    redirectTo,
    setVisible,
    collapsibleElements,
    router,
}) => {
  const [expend, setExpend] = useState(false);
  const ref = useRef(null);
  useOutsideClick(ref, () => setVisible(false));

  const expendItem = (id) => {
      setExpend(id === expend ? false : id)
  }

  const handleOnClick = (item) => {
        const currentUrl = router.pathname;
        if(item?.gTag) triggerCustomEvent(item?.gTag);
        if(currentUrl === item?.redirect){
            setVisible(false)
        }else if(item?.redirect){
            if(item?.isOpenInNewTab) {
                window.open(item?.redirect, '_blank')
            } else {
            redirectTo(item?.redirect)
            }
        }else if(item?.collapse){
            expendItem(item?.id)
        }
  }

  return (
        <div
            className={`${
                visible ? 'flex xl:hidden' : 'hidden'
            } bg-basicBlack/40 rounded-t-2xl left-0 scroll-vertical-width-0 fixed w-full bottom-16 flex-col h-full items-center overflow-visible justify-end`}
            style={{ zIndex: 98 }}
        >
            <div ref={ref} className="w-full rounded-t-xl bg-basicWhite max-h-[75%] flex flex-col items-center relative bottom-navigation-panel animate__slideInUp animate__animated gap-2 py-5">
            <button className='bg-basicWhite p-1 rounded-full absolute z-50 -top-10' onClick={() => setVisible(false)}><XIcon className='w-6 h-6'/></button>
                <div className='flex flex-col items-center gap-6 w-full overflow-y-auto scroll-vertical-width-0'>
                    {list?.map((item, index) => (
                        <div key={index} className="px-3 self-start w-full cursor-pointer">
                            <div className={`justify-between flex items-center gap-3 ${item?.className || '' }`} onClick={() => handleOnClick(item)}>
                                <div className={`p-2 rounded-lg ${item?.imageClass || 'bg-gray-50' }`}>
                                    <Image src={item?.image} className="w-6 h-6" />
                                </div>
                                <Text content={item?.label} className={`p5-medium ${item?.textColor || 'text-gray-500'} w-full`} />
                                {item?.suffixImage ? <Image src={item?.suffixImage} className="h-5 pr-2" /> : null}
                                {item?.collapse ? <button>{expend === item?.id ? <ChevronUpIcon className='w-4 h-4'/> : <ChevronDownIcon className='w-4 h-4'/>}</button> : null}
                            </div>
                            {expend === item?.id ? <div className='block w-full'>{collapsibleElements(expend)}</div> : null}
                        </div>
                    ))}
                     <Link href='/' className='text-primary-500 p6-underline mb-4' 
                        onClick={() => {
                            handleOnClick({ redirect: '/' })
                            triggerCustomEvent(gaEventNameEnum.CLICKED_HOME_PAGE_MORE_MOBILE_WEB_NAVIGATION)
                        }}
                    >
                        Homepage
                    </Link>
                </div>
            </div>
        </div>
  );
}